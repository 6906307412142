body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323130;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Segoe UI;
  font-weight: 700;
  src: url("./fonts/segoe-ui-bold.woff2") format("woff2");
}

@font-face {
  font-family: Segoe UI;
  font-weight: 600;
  src: url("./fonts/segoe-ui-semibold.woff2") format("woff2");
}

@font-face {
  font-family: Segoe UI;
  font-weight: 400;
  src: url("./fonts/segoe-ui.woff2") format("woff2");
}

@font-face {
  font-family: Segoe UI;
  font-weight: 300;
  src: url("./fonts/segoe-ui-semilight.woff2") format("woff2");
}

@font-face {
  font-family: Segoe UI;
  font-weight: 200;
  src: url("./fonts/segoe-ui-light.woff2") format("woff2");
}

/*
  Make anchor outline color consistent across browsers
  Fixes anchor focus outline color in Electron doesn't meet contrast ratio
*/
a:focus {
  outline-color: #000000;
}
